.pagination-list {
	display: inline-block;
	position: relative;
}

.pagination a {
	float: left;
	padding: 8px 16px;
	text-decoration: none;
	cursor: pointer;
}

.selected {
	border-radius: 5px;
}

.pagination li:last-child,
.pagination li:first-child {
	border-radius: 5px;
}

.pagination a:hover:not(.selected) {
	border-radius: 5px;
}

.pagination ul {
	list-style-type: none;
	overflow: hidden;
}
	
.pagination {
	margin: 0 auto;
	width: fit-content;
	width: -moz-fit-content;
	width: -webkit-fit-content;
}

.pagination li {
	display: inline-block;
}

.pagination li:first-child {
	position: absolute;
	left: 10px;
}

.pagination li:last-child {
	position: absolute;
	right: 10px;
}

.pagination-controls {
	padding-top: 10px;
	padding-bottom: 10px;
}

thead {
	font-size: 15px;
}
